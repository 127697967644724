
.knt-success {
	background-color: #6bff70;
	border: 1px solid black;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
}

p {
    display: inline-block;
}
.sample-canvas{
    height: 1000px;
    width: 100%;
    position: absolute;
}

.knt-error {
	animation-duration: 10800ms;
	animation-name: error;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	background-color: rgba(247, 133, 118, 0.815);
	color: black;
    -webkit-animation: error 10800ms infinite; /* Safari and Chrome */
	border: 1px solid rgba(255, 0, 0, 0.384);
	vertical-align: middle;
	text-align: center;

}

@keyframes error {
	from {
		background-color: red;
	}
}

@-webkit-keyframes error {
	from {
		background-color: red;
		
	}
}

.knt-default{
	background-color: gray;
	border: 1px dashed black;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	
}

.knt-idle{
	background-color: rgb(125, 125, 255);
	border: 1px solid black;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
}

.knt-warrning{
	background-color: #fffd8e;
	border: 1px solid black;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	
}

.knt-empty{
	background-color:#bdc2bd;
	border: 1px dashed black;
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
}

.knt-production-low{
	font-weight: bold;
	color: red;
	font-size: 10px;
}

.knt-production-middle{
	font-weight: bold;
	color: rgb(224, 202, 0);
	font-size: 10px;
}

.knt-production-max{
	font-weight: bold;
	color: green;
	font-size: 10px;
}

.confetti {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
}
.confetti-piece {
    position: absolute;
    width: 10px;
    height: 30px;
    background: #ffd300;
    top: 0;
    opacity: 0;
}

.confetti-piece:nth-child(1) {
    left: 7%;
    -webkit-transform: rotate(-40deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 182ms;
    -webkit-animation-duration: 1116ms;
}

.confetti-piece:nth-child(2) {
    left: 14%;
    -webkit-transform: rotate(4deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 161ms;
    -webkit-animation-duration: 1076ms;
}

.confetti-piece:nth-child(3) {
    left: 21%;
    -webkit-transform: rotate(-51deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 481ms;
    -webkit-animation-duration: 1103ms;
}

.confetti-piece:nth-child(4) {
    left: 28%;
    -webkit-transform: rotate(61deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 334ms;
    -webkit-animation-duration: 708ms;
}

.confetti-piece:nth-child(5) {
    left: 35%;
    -webkit-transform: rotate(-52deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 302ms;
    -webkit-animation-duration: 776ms;
}

.confetti-piece:nth-child(6) {
    left: 42%;
    -webkit-transform: rotate(38deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 180ms;
    -webkit-animation-duration: 1168ms;
}

.confetti-piece:nth-child(7) {
    left: 49%;
    -webkit-transform: rotate(11deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 395ms;
    -webkit-animation-duration: 1200ms;
}

.confetti-piece:nth-child(8) {
    left: 56%;
    -webkit-transform: rotate(49deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 14ms;
    -webkit-animation-duration: 887ms;
}

.confetti-piece:nth-child(9) {
    left: 63%;
    -webkit-transform: rotate(-72deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 149ms;
    -webkit-animation-duration: 805ms;
}

.confetti-piece:nth-child(10) {
    left: 70%;
    -webkit-transform: rotate(10deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 351ms;
    -webkit-animation-duration: 1059ms;
}

.confetti-piece:nth-child(11) {
    left: 77%;
    -webkit-transform: rotate(4deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 307ms;
    -webkit-animation-duration: 1132ms;
}
.confetti-piece:nth-child(12) {
    left: 84%;
    -webkit-transform: rotate(42deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 464ms;
    -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(13) {
    left: 91%;
    -webkit-transform: rotate(-72deg);
    -webkit-animation: makeItRain 1000ms infinite ease-out;
    -webkit-animation-delay: 429ms;
    -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(odd) {
    background: #7431e8;
}
.confetti-piece:nth-child(even) {
    z-index: 1;
}
.confetti-piece:nth-child(4n) {
    width: 5px;
    height: 12px;
    -webkit-animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
    width: 3px;
    height: 10px;
    -webkit-animation-duration: 2500ms;
    -webkit-animation-delay: 1000ms;
}
.confetti-piece:nth-child(4n-7) {
  background: red;
}

@-webkit-keyframes makeItRain {
    from {opacity: 0;}
    50% {opacity: 1;}
    to {-webkit-transform: translateY(350px);}
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  .sensor{
    display: table-cell;
    padding: 10px;
    vertical-align: middle;
    position:absolute;
    width: 150px;
  }

  .sensor2{
    position:absolute;
  }

  
[data-color-mode*="dark"],
[data-color-mode*="dark"] body {
  --color-canvas-subtle: #161b22;
}

[data-color-mode*="light"],
[data-color-mode*="light"] body {
  --color-canvas-subtle: #f6f8fa;
}
